<template>
  <div class="container-fluid py-4">
    <div class="row row-style">
      <div class="table-responsive-lg col-12">
        <div class="card col-md-12" id="table-card">
          <div class="form-group">
            <input type="text" class="form-control search-table" v-model="searchTerm" placeholder="Pesquisar">
          </div>
        </div>
        <table class="table table-hover table-pastel mb-0 mt-0">
          <thead class="thead-dark">
            <tr>
              <th>Data</th>
              <th>Animal</th>
              <th>Raquete</th>
              <th>PE</th>
              <th>AE</th>
              <th>AD</th>
              <th>PD</th>
              <th>Opções</th>
            </tr>
          </thead>
          <tbody>
            <tr class="text-capitalize" v-for="item in filteredList" :key="item.reading_id">
              <td>{{ formatDate(item.updated_at) }}</td>
              <td>{{ item.surname }}</td>
              <td>{{ item.serial }}</td>
              <td>{{ item.vase1 }}</td>
              <td>{{ item.vase2 }}</td>
              <td>{{ item.vase3 }}</td>
              <td>{{ item.vase4 }}</td>
              <td>
                <button class="btn btn-danger" @click="deleteReadings(item.reading_id)">Excluir</button>
                <button class="btn btn" style=" background-color:blueviolet; margin-left:4px; color:#ffff"
                  @click="openPrescriptionModal(item.reading_id)">Prescrição do animal</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Modal de Prescrição -->
    <div v-if="showPrescriptionModal" class="modal fade show" tabindex="-1" role="dialog"
      style="display: block; width: 100%; margin: auto;">
      <div class="modal-dialog custom-modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header" W>
            <h2 class="modal-title">Adicionar Prescrição</h2>
          </div>
          <div class="modal-body">
            <div class="row">
              <!-- Formulário de Prescrição -->
              <div class="col-md-6">
                <form @submit.prevent="submitPrescription">
                  <div class="mb-3">
                    <label for="animal" class="form-label">Nome do Animal</label>
                    <input type="text" v-model="animal" class="form-control" id="animal" readonly>
                  </div>
                  <div class="row">
                    <div class="col-md-6 mb-3">
                      <label for="vase1" class="form-label">Vaso 1</label>
                      <input type="text" v-model="vase1" class="form-control" id="vase1" readonly>
                    </div>
                    <div class="col-md-6 mb-3">
                      <label for="vase2" class="form-label">Vaso 2</label>
                      <input type="text" v-model="vase2" class="form-control" id="vase2" readonly>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 mb-3">
                      <label for="vase3" class="form-label">Vaso 3</label>
                      <input type="text" v-model="vase3" class="form-control" id="vase3" readonly>
                    </div>
                    <div class="col-md-6 mb-3">
                      <label for="vase4" class="form-label">Vaso 4</label>
                      <input type="text" v-model="vase4" class="form-control" id="vase4" readonly>
                    </div>
                  </div>
                  <div class="mb-3">
                    <label for="prescription" class="form-label">Prescrição</label>
                    <input type="text" v-model="prescription" class="form-control" id="prescription" required>
                  </div>
                  <div class="mb-3">
                    <label for="observation" class="form-label">Observação</label>
                    <input type="text" v-model="observation" class="form-control" id="observation">
                  </div>
                  <div class="mb-3">
                    <label for="discardDate" class="form-label">Data do Descarte</label>
                    <input type="date" v-model="discardDate" class="form-control" id="discardDate">
                  </div>
                </form>
              </div>
              <!-- lista de prescricao -->
              <div class="col-md-6 prescriptions-table-container">
                <h5>Prescrições Anteriores</h5>
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Data</th>
                      <th>Prescrição</th>
                      <th>Observação</th>
                      <th>Data de Descarte</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="prescription in prescriptions" :key="prescription.id">
                      <td>{{ formatDate(prescription.created_at) }}</td>
                      <td>{{ prescription.prescription }}</td>
                      <td>{{ prescription.observation }}</td>
                      <td>{{ prescription.discard }}</td>
                      <td>
                        <button class="btn btn-primary" @click="editPrescription(prescription)">Editar</button>
                        <button class="btn btn-danger" @click="deletePrescription(prescription.id)">Excluir</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closePrescriptionModal">Cancelar</button>
            <button type="button" class="btn btn-primary" @click="submitPrescription">Salvar Prescrição</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import axios from 'axios';

export default {
  data() {
    return {
      searchTerm: '',
      list: [],
      prescriptions: [],
      company: localStorage.getItem('subEmpresaSelecionada') ?? localStorage.getItem('company'),
      servidor: 'https://api001.bia.ind.br',
      showPrescriptionModal: false,
      animal: '',
      vase1: '',
      vase2: '',
      vase3: '',
      vase4: '',
      prescription: '',
      observation: '',
      discardDate: '',
      fk_squad: null,
      fk_reading: null,
      selectedReadingId: null,
      selectedPrescriptionId: null,
    }
  },
  mounted() {
    this.getList();
  },
  computed: {
    filteredList() {
      const term = this.searchTerm.toLowerCase();
      return this.list.filter(item => this.deepSearch(item, term));
    },
  },
  methods: {
    getList() {
      axios.get(`${this.servidor}/api/readings/get-by-company/${this.company}`, {
        headers: { 'Accept': 'application/json' },
      })
        .then(response => { this.list = response.data; })
        .catch(error => { console.error(error); });
    },
    openPrescriptionModal(readingId) {
      const selectedItem = this.list.find(item => item.reading_id === readingId);
      if (selectedItem) {
        this.selectedReadingId = readingId;
        this.animal = selectedItem.surname;
        this.vase1 = selectedItem.vase1;
        this.vase2 = selectedItem.vase2;
        this.vase3 = selectedItem.vase3;
        this.vase4 = selectedItem.vase4;
        this.fk_squad = selectedItem.squad_id;
        this.fk_reading = selectedItem.reading_id;
        this.fetchPrescriptions(this.fk_squad);
        this.showPrescriptionModal = true;
      } else {
        console.warn("Nenhum item encontrado com o ID fornecido.");
      }
    },
    fetchPrescriptions(squadId) {
      axios.get(`${this.servidor}/api/squads/prescriptions/${squadId}`)
        .then(response => { this.prescriptions = response.data; })
        .catch(error => { console.error("Erro ao buscar as prescrições:", error.response ? error.response.data : error.message); });
    },
    submitPrescription() {
      if (!this.prescription || !this.fk_squad || !this.fk_reading) {
        alert('Por favor, insira todos os dados.');
        return;
      }

      const data = {
        prescription: this.prescription,
        observation: this.observation,
        fk_company_veterinarian: this.company,
        fk_squad: this.fk_squad,
        fk_reading: this.fk_reading,
        discard: this.discardDate,
      };

      axios.post(`${this.servidor}/api/prescriptions`, data)
        .then(response => {
          alert('Prescrição salva com sucesso!');
          this.closePrescriptionModal();
          this.getList();
        })
        .catch(error => {
          console.error("Erro ao salvar a prescrição:", error.response ? error.response.data : error.message);
          alert('Ocorreu um erro ao salvar a prescrição.');
        });
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString('pt-BR');
    },
    deepSearch(obj, term) {
      if (typeof obj === 'object' && obj !== null) {
        for (const key in obj) {
          if (this.deepSearch(obj[key], term)) {
            return true;
          }
        }
      } else if (typeof obj === 'string') {
        return obj.toLowerCase().includes(term);
      }
      return false;
    },
    deleteReadings(readingId) {
      axios.delete(`${this.servidor}/api/readings/${readingId}`)
        .then(response => {
          alert('Leitura excluída com sucesso!');
          this.getList();
        })
        .catch(error => {
          console.error("Erro ao excluir leitura:", error.response ? error.response.data : error.message);
          alert('Ocorreu um erro ao excluir a leitura.');
        });
    },

    deletePrescription(prescriptionId) {
      axios.delete(`${this.servidor}/api/prescriptions/${prescriptionId}`)
        .then(response => {
          alert('Prescrição excluída com sucesso!');
          this.fetchPrescriptions(this.fk_squad);
        })
        .catch(error => {
          console.error("Erro ao excluir prescrição:", error.response ? error.response.data : error.message);
          alert('Ocorreu um erro ao excluir a prescrição.');
        });
    },
    
    closePrescriptionModal() {
      this.showPrescriptionModal = false;
      this.prescription = '';
      this.observation = '';
      this.discardDate = '';
    },
    editPrescription(prescription) {
      this.selectedPrescriptionId = prescription.id;
      this.prescription = prescription.prescription;
      this.observation = prescription.observation;
      this.discardDate = prescription.discard;
    }
  }
}
</script>


<style scoped>
.search-table {
  background-color: #f0f0f0;
  margin-bottom: 20px;
  font-size: 18px;
  padding: 10px;
}

.table-pastel thead th {
  background-color: #333333;
  color: #333333;
}


.table-pastel tbody tr {
  background-color: #f9f9f9;
  font-size: 16px;
}

.custom-modal-lg {
  max-width: 90%;
  /* Ajuste a largura conforme necessário */
  width: 90%;
  /* Ajuste a largura conforme necessário */
}

.modal-header {
  background-color: #ffffff;
}

.table-pastel tbody tr:hover {
  background-color: #ebebeb;
}


.modal-backdrop.show {
  display: none;
}

.modal.show {
  display: block;
  background-color: rgba(0, 0, 0, 0.5);
  overflow-y: auto;
}

.modal-dialog {
  margin: 30px auto;
}

.modal-content {
  border-radius: 8px;
}

.modal-header,
.modal-body,
.modal-footer {
  padding: 20px;
}

.modal-backdrop.show {
  display: block;
}

.prescriptions-table-container {
  max-height: 400px;
  /* Ajuste conforme necessário */
  overflow-y: auto;
}

.table-pastel {
  margin-bottom: 0;
}
</style>
