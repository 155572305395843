import { createRouter, createWebHistory } from 'vue-router'
import { useStore } from 'vuex' // Se você estiver usando Vuex

import CadastroPlantelView from '../views/CadastroPlantelView.vue'
import CadastroEmpresaView from '../views/CadastroEmpresaView.vue'
import VincularEmpresaView from '../views/VincularEmpresaView.vue'
import CadastroSubEmpresaView from '../views/CadastroSubEmpresaView.vue'
import CadastroRaqueteView from '../views/CadastroRaqueteView.vue'
import CadastroRacaView from '../views/CadastroRacaView.vue'
import CadastroUsuarioView from '../views/CadastroUsuarioView.vue'
import PlantelView from '../views/PlantelView.vue'
import EmpresasView from '../views/EmpresasView.vue'
import SubEmpresasView from '../views/SubEmpresasView.vue'
import EmpresasVinculadasView from '../views/EmpresasVinculadasView.vue'
import RaquetesView from '../views/RaquetesView.vue'
import LeiturasView from '../views/LeiturasView.vue'
import UsuariosView from '../views/UsuariosView.vue'
import TermosView from '../views/TermosView.vue'
// import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
// import IntroView from '../views/IntroView.vue'
import PrivacidadeView from '../views/PrivacidadeView.vue'

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/novo-animal',
    name: 'adicionar animal',
    component: CadastroPlantelView
  },
  {
    path: '/nova-empresa',
    name: 'adicionar empresa',
    component: CadastroEmpresaView
  },
  {
    path: '/nova-vinculacao',
    name: 'vinculacao de empresas',
    component: VincularEmpresaView
  },
  {
    path: '/nova-sub-empresa',
    name: 'adicionar sub empresa',
    component: CadastroSubEmpresaView
  },
  {
    path: '/nova-raquete',
    name: 'adicionar raquete',
    component: CadastroRaqueteView
  },
  // {
  //   path: '/nova-leitura',
  //   name: 'adicionar raça',
  //   component: CadastroRacaView
  // },
  {
    path: '/novo-usuario',
    name: 'adicionar usuário',
    component: CadastroUsuarioView
  },
  {
    path: '/dashboard-plantel',
    name: 'dashboard animais',
    component: PlantelView
  },
  {
    path: '/dashboard-empresas',
    name: 'dashboard empresas',
    component: EmpresasView
  },
  {
    path: '/dashboard-sub-empresas',
    name: 'dashboard sub empresas',
    component: SubEmpresasView
  },
  {
    path: '/dashboard-empresas-vinculadas',
    name: 'dashboard empresas vinculadas',
    component: EmpresasVinculadasView
  },
  {
    path: '/dashboard-raquetes',
    name: 'dashboard raquetes',
    component: RaquetesView
  },
  {
    path: '/dashboard-leituras',
    name: 'dashboard leituras',
    component: LeiturasView
  },
  {
    path: '/dashboard-usuarios',
    name: 'dashboard usuarios',
    component: UsuariosView
  },
  {
    path: '/termos',
    name: 'termos',
    component: TermosView
  },
  {
    path: '/Privacidade',
    name: 'Privacidade',
    component: PrivacidadeView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Middleware de autenticação
router.beforeEach((to, from, next) => {
  const publicPages = ['/', '/termos','/Privacidade'];
  const authRequired = !publicPages.includes(to.path);
  const token = localStorage.getItem('token');

  console.log(`Navigating to: ${to.path}`);
  console.log(`Token present: ${!!token}`);
  console.log(`Auth required: ${authRequired}`);

  if (authRequired && !token) {
    console.log('Not authenticated, redirecting to login');
    next('/');
  }

  if (to.path === '/' && token) {
    console.log('Already authenticated, redirecting to dashboard');
    next(false);
    return next();
  }

  next();
});

export default router
