<template>
        <h1>Política de Privacidade</h1>
 <p>
    <strong>Política de Privacidade</strong>

 </p>       
<p>
  Esta política de privacidade se aplica ao aplicativo Veterinário Vaca Roxa (doravante referido como "Aplicativo") para dispositivos móveis, criado pela VACA ROXA INDÚSTRIA DE EQUIPAMENTOS CLÍNICOS LTDA (doravante referido como "Prestador de Serviço") como um serviço gratuito. Este serviço é destinado para uso "COMO ESTÁ".
</p>
<h2>Coleta e Uso de Informações</h2>
<p>
  O Aplicativo coleta informações quando você o baixa e utiliza. Essas informações podem incluir:
</p>
<ul>
  <li>O endereço de Protocolo de Internet do seu dispositivo (por exemplo, endereço IP)</li>
  <li>As páginas do Aplicativo que você visita, a hora e a data da sua visita, o tempo gasto nessas páginas</li>
  <li>O tempo gasto no Aplicativo</li>
  <li>O sistema operacional que você utiliza no seu dispositivo móvel</li>
</ul>
<p>
  O Aplicativo não coleta informações precisas sobre a localização do seu dispositivo móvel.
</p>
<p>
  O Prestador de Serviço pode usar as informações fornecidas por você para entrar em contato ocasionalmente, para fornecer informações importantes, avisos necessários e promoções de marketing.
</p>
<p>
  Para uma melhor experiência ao usar o Aplicativo, o Prestador de Serviço pode solicitar que você forneça certas informações pessoalmente identificáveis, incluindo, mas não se limitando a e-mail e senha. As informações solicitadas pelo Prestador de Serviço serão retidas por ele e utilizadas conforme descrito nesta política de privacidade.
</p>
<h2>Acesso de Terceiros</h2>
<p>
  Somente dados agregados e anonimizados são periodicamente transmitidos a serviços externos para ajudar o Prestador de Serviço a melhorar o Aplicativo e seu serviço. O Prestador de Serviço pode compartilhar suas informações com terceiros nas maneiras descritas nesta declaração de privacidade.
</p>
<p>
  Observe que o Aplicativo utiliza serviços de terceiros que têm suas próprias Políticas de Privacidade sobre o manejo de dados. Abaixo estão os links para a Política de Privacidade dos provedores de serviços de terceiros utilizados pelo Aplicativo:
</p>
<ul>
  <li>Google Play Services</li>
  <li>Google Analytics para Firebase</li>
  <li>Firebase Crashlytics</li>
</ul>
<p>
  O Prestador de Serviço pode divulgar as Informações Fornecidas pelo Usuário e as Informações Coletadas Automaticamente:
</p>
<ul>
  <li>conforme exigido por lei, como para cumprir uma intimação ou processo legal semelhante;</li>
  <li>quando acreditarem de boa fé que a divulgação é necessária para proteger seus direitos, proteger sua segurança ou a segurança de outros, investigar fraude ou responder a uma solicitação do governo;</li>
  <li>com seus provedores de serviços confiáveis que trabalham em seu nome, não têm um uso independente das informações que divulgamos a eles e concordaram em aderir às regras estabelecidas nesta declaração de privacidade.</li>
</ul>
<h2>Direitos de Opt-Out</h2>
<p>
  Você pode parar toda a coleta de informações pelo Aplicativo facilmente desinstalando-o. Você pode utilizar os processos padrão de desinstalação que podem estar disponíveis como parte do seu dispositivo móvel ou através do mercado de aplicativos móveis ou rede.
</p>
<h2>Política de Retenção de Dados</h2>
<p>
  O Prestador de Serviço reterá os dados fornecidos pelo usuário enquanto você utilizar o Aplicativo e por um tempo razoável após isso. Se você desejar que eles excluam os dados fornecidos pelo usuário que você forneceu por meio do Aplicativo, entre em contato através do e-mail vacaroxaa@gmail.com e eles responderão em um tempo razoável.
</p>
<h2>Crianças</h2>
<p>
  O Prestador de Serviço não utiliza o Aplicativo para solicitar dados ou fazer marketing para crianças menores de 13 anos. O Aplicativo não é direcionado a ninguém com menos de 13 anos. O Prestador de Serviço não coleta conscientemente informações pessoalmente identificáveis de crianças menores de 13 anos. Caso o Prestador de Serviço descubra que uma criança menor de 13 anos forneceu informações pessoais, ele as excluirá imediatamente de seus servidores. Se você é pai ou responsável e está ciente de que seu filho forneceu informações pessoais, entre em contato com o Prestador de Serviço (vacaroxaa@gmail.com) para que possam tomar as medidas necessárias.
</p>
<h2>Segurança</h2>
<p>
  O Prestador de Serviço está preocupado em proteger a confidencialidade de suas informações. O Prestador de Serviço fornece salvaguardas físicas, eletrônicas e processuais para proteger as informações que processa e mantém.
</p>
<h2>Alterações</h2>
<p>
  Esta Política de Privacidade pode ser atualizada de tempos em tempos por qualquer motivo. O Prestador de Serviço notificará você de quaisquer alterações à Política de Privacidade atualizando esta página com a nova Política de Privacidade. Você é aconselhado a consultar esta Política de Privacidade regularmente para quaisquer alterações, pois o uso contínuo é considerado aprovação de todas as mudanças.
</p>
<p>
  Esta política de privacidade é efetiva a partir de 29 de junho de 2024.
</p>
<h2>Seu Consentimento</h2>
<p>
  Ao usar o Aplicativo, você consente com o processamento de suas informações conforme estabelecido nesta Política de Privacidade agora e conforme alterado por nós.
</p>
<h2>Contato</h2>
<p>
  Se você tiver qualquer dúvida sobre privacidade ao usar o Aplicativo, ou tiver perguntas sobre as práticas, entre em contato com o Prestador de Serviço via e-mail em vacaroxaa@gmail.com.
</p>
</template>

<script>
export default {
  data() {
    return {
      view: 'PrivacidadeView'  // Estado inicial para exibir os Termos e Condições
    }
  }
}
</script>

<style>
.container {
  padding: 20px;
  max-width: 800px;
  margin: auto;
}

h2 {
  margin-bottom: 20px;
}

p {
  text-align: justify;
  margin-bottom: 20px;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
</style>