<template>
    <div class="container">
      <Privacidade />
    </div>
</template>
  
  <script>
  import Privacidade from '@/components/Privacidade.vue';
  
  export default {
    name: 'PrivacidadeView',  // Nome do componente exportado
    components: {
      Privacidade,
    },
  }
  </script>
  
  <style>
  
  </style>
  